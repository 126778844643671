import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const WebListerContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  color: ${(props) => props.theme.palette.text.t1};
  background: ${(props) => props.theme.palette.background.bg2};
  & > h2 {
    margin-top: 0;
    padding: 0;
  }
  & > h1 {
    margin-top: 0;
    font: 1.5rem/1.25 Onest-Bold;
    padding: 0;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    padding-top: 16px;
    padding-bottom: 56px;
    & > h2 {
      margin-top: 0;
      padding: 0 16px;
    }
    & > h1 {
      margin-top: 0;
      padding: 0 16px;
    }
  }
`;

export const ListerOptions = styled.div`
  display: flex;
  height: 80px;
  align-items: center;
`;

export const ListerLeft = styled.div`
  justify-content: flex-start;
  flex: 1;
`;

export const ListerRight = styled.div`
  justify-content: flex-end;
  flex: 1;
`;

export const CardsContainer = styled.div`
  display: grid;
  padding-top: 20px;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  @media (max-width: ${WIDTH.mobileMax}) {
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    padding: 16px;
  }
`;
export const ListContainer = styled.div`
  display: none;
  @media (max-width: 900px) {
    display: block;
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProductCardContainer = styled.div`
  width: 100%;
  background: ${(props) => props.theme.palette.background.bg1};
  box-shadow: ${(props) => props.theme.shadows.s1};
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  gap: 12px;
`;

export const ImageContainer = styled.div`
  width: 148px;
  min-width: 148px;
  height: 203px;
  max-height: 203px;
  border-radius: 8px;
  position: relative;
  & img {
    border-radius: 8px;
  }
`;

export const ProductCardMain = styled.main`
  display: flex;
  flex-direction: column;
`;

export const ProductTitle = styled.span`
  -webkit-line-clamp: 2;
  max-width: 638px;
  text-overflow: ellipsis;
  overflow: hidden;
  font: 1.125rem/1.375rem Onest-SemiBold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-bottom: 6px;
  color: ${(props) => props.theme.palette.text.t1};
  margin-bottom: 12px;
  @media (max-width: ${WIDTH.tabletMax}) {
    font: 1rem/1.25rem Onest-SemiBold;
  }
`;

export const ProductDetailsContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: start;
  flex-grow: 1;
`;

export const ProductDetail = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  & > span:last-of-type {
    font-size: 14px;
    text-transform: uppercase;
    font-family: Onest-Bold;
    line-height: 18px;
    color: ${(props) => props.theme.palette.text.t2};
  }
`;

export const ProductPrice = styled.p`
  font-size: 26px;
  line-height: 30px;
  font-family: Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
`;

export const NoProductsFoundContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  display: grid;
  place-content: center;
  justify-items: center;

  & > p:first-of-type {
    font-family: Onest-Bold;
    font-style: normal;
    font-size: 26px;
    line-height: 30px;
    color: ${(props) => props.theme.palette.text.t2};
    text-align: center;
    margin-bottom: 8px;
  }
  & > p:last-of-type {
    font-family: Onest-Medium;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    color: ${(props) => props.theme.palette.text.t2};
    text-align: center;
    margin-bottom: 8px;
  }
`;

export const MobileListerHeaderContainer = styled.div`
  border-radius: 8px;
  width: 100%;
  background: ${(props) => props.theme.palette.background.bg1};
  padding: 12px 0;
  margin-top: 16px;
  box-shadow: ${(props) => props.theme.shadows.s2};
`;

export const WebWrapper = styled.div`
  display: block;
  @media (max-width: ${WIDTH.mobileMax}) {
    display: none;
  }
`;

export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: ${WIDTH.mobileMax}) {
    display: block;
  }
`;
