import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { memo } from 'react';
import { ProductSectionCardInterface } from '../../Interface/ProductCardInterface';
import { ProductSearchInterface } from '../../Interface/ProductSearchInterface';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { useLocale } from '../../contexts/LocalizationContext';
import {
  displayCurrencyValue,
  getDiscount,
  getRegionFromRegionId,
} from '../../helper/helper';
import { useAppSelector } from '../../redux/hooks';
import { saveEventV3 } from '../../utils/eventTracking';
import { getProductSkuFromSlug } from '../../utils/getProductSkuFromSlug';
import {
  getLocalStorageExp,
  saveLocalStorageExp,
} from '../../utils/localStorageUtils';
import { saveSelectedRecommendations } from '../../utils/recombee';
import { WE_USER_EVENTS_SEARCH_PRODUCT_CLICKED } from '../../utils/we';
import { exportPlatformIcon } from '../Icons/PlatformIcons';
import { Spinner } from '../LoaderDots/LoaderStyle';
import Button from '../NewButton/Button';
import { DiscountTag, MrpTagSec } from '../OtherOffersRow/OtherOffersRowStyle';
import NoProductsFound from '../WebLister/components/NoProductsFound';
import {
  AutocompleteContainer,
  AutocompleteHead,
  AutocompleteList,
  DetailsContainer,
  ImageContainer,
  ImageTag,
  ListDetailsContainer,
  ListItem,
  ListTitle,
  NoProductFoundText,
  PreReleasedTag,
  PriceValue,
  ProductRegion,
  SearchLoaderContainer,
  SimpleText,
} from './SearchAutocompleteStyle';
import { setItmCookie } from '../../utils/itmTracking';
const Link = dynamic(() => import('next/link'), {
  ssr: false,
});
// import { setProducts } from '../../redux/listerSlice';

interface Props {
  display: boolean;
  cursor: number;
  products: Array<ProductSearchInterface> | null;
  searchText: string;
  clickOutside: (x: boolean) => void;
  goToStore: () => void;
  mobileInputRef?: React.RefObject<HTMLInputElement>;
  recommendationId?: string;
}

export const addToRecentSearches = (
  product: ProductSearchInterface | ProductSectionCardInterface
) => {
  const key = 'recentSearches';
  const currentRecentSearches: any = getLocalStorageExp(key);
  let newRecentSearches: object;

  if (!currentRecentSearches) {
    newRecentSearches = {
      [product.slug]: {
        title: product.title,
        image: product.image,
        isPreReleased: product.isPreReleased,
        releaseDate: product.releaseDate,
      },
    };
  } else {
    newRecentSearches = {
      [product.slug]: {
        title: product.title,
        image: product.image,
        isPreReleased: product.isPreReleased,
        releaseDate: product.releaseDate,
      },
      ...JSON.parse(currentRecentSearches),
    };
  }
  saveLocalStorageExp(key, newRecentSearches, 10080);
};

const SearchAutocomplete = (props: Props) => {

  const { locationDetails } = useAppSelector((state) => state.global);
  const router = useRouter();

  const { messages } = useLocale();
  const { related_results_msg, oos_msg } = messages || {};

  const { cleverTap } = useAnalytics();

  return (
    <AutocompleteContainer
      style={{
        opacity: props.display ? 1 : 0,
        pointerEvents: props.display ? 'auto' : 'none',
      }}
    >
      <AutocompleteList>
        <AutocompleteHead>{related_results_msg}</AutocompleteHead>
        {props.searchText === 'loading' ? (
          <SearchLoaderContainer>
            <Spinner />
          </SearchLoaderContainer>
        ) : props.products && props.products.length >= 1 ? (
          props.products.map((product, index) => {
            let discount = product
              ? getDiscount(product.mrp, product.price) ?? 0
              : 0;

            return (
              <Link
                href={`/${product.slug}`}
                passHref
                key={index}
                prefetch={false}
              >
                <a
                  onClick={() => {
                    if (
                      getProductSkuFromSlug(product.slug) &&
                      props.recommendationId
                    ) {
                      saveSelectedRecommendations(
                        getProductSkuFromSlug(product.slug) as string,
                        props.recommendationId
                      );
                    }
                    addToRecentSearches(product);
                    WE_USER_EVENTS_SEARCH_PRODUCT_CLICKED(
                      {
                        productImage: product.image,
                        productName: product.title,
                        productPlatform: product.platform as string,
                        basePrice: product.price
                          ? product.price.toString()
                          : '',
                        productRegion: product?.regionName
                          ? product?.regionName
                          : getRegionFromRegionId(product.regionId) || '-',
                        searchQuery: props.searchText,
                        pageUrl: window.location.href,
                        currency: locationDetails.currency ?? '',
                      },
                      cleverTap
                    );
                    saveEventV3({
                      category: 'header',
                      action: 'click',
                      label: 'search_result_click',
                      properties: product.slug,
                      value: [props.searchText],
                      from: router,
                    });
                    setItmCookie({
                      source: 'Header',
                      medium: 'search',
                      campaign:'',
                      content: product.title || '',
                    });
                    props.clickOutside(false);
                  }}
                >
                  <ListItem
                    key={index}
                    tabIndex={0}
                    selected={index === props.cursor}
                  >
                    <ImageContainer>
                      <ImageTag src={product.image} />
                    </ImageContainer>
                    <DetailsContainer>
                      <ListTitle>{product.title}</ListTitle>
                      <ListDetailsContainer
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        {/* {product.platform ? (
                        <ProductSearchPill
                          attribute="Playable on"
                          value={product.platform}
                        />
                      ) : null}
                      {product.attributes.region &&
                      product.attributes.regionId ? (
                        <ProductSearchPill
                          attribute="Region"
                          value={product.attributes.regionId}
                        />
                      ) : null} */}
                        {product.regionId ? (
                          <ProductRegion>
                            {product?.platform
                              ? exportPlatformIcon(
                                  product.platform,
                                  '16px',
                                  '16px',
                                  '0 0 0 0'
                                )
                              : null}
                            {product?.regionName
                              ? product?.regionName
                              : getRegionFromRegionId(product.regionId) || '-'}
                            {product.isPreReleased ? (
                              <PreReleasedTag>Pre-order</PreReleasedTag>
                            ) : null}
                          </ProductRegion>
                        ) : null}
                      </ListDetailsContainer>
                      <div
                        style={{
                          display: 'flex',
                          flex: 1,
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {product.price ? (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {/* <SimpleText>From </SimpleText> */}
                            <PriceValue>
                              {displayCurrencyValue(
                                product.price,
                                locationDetails?.currency_symbol,
                                locationDetails?.multiplier
                              )}
                            </PriceValue>
                            {!!product.mrp &&
                            product.price &&
                            discount &&
                            discount > 0 ? (
                              <MrpTagSec>
                                <s>
                                  {displayCurrencyValue(
                                    product.mrp,
                                    locationDetails?.currency_symbol,
                                    locationDetails?.multiplier
                                  )}
                                </s>
                                <DiscountTag>
                                  <p>~{discount && discount}% off</p>
                                </DiscountTag>
                              </MrpTagSec>
                            ) : null}
                          </div>
                        ) : (
                          <>
                            <SimpleText>{oos_msg}</SimpleText>
                          </>
                        )}
                      </div>
                    </DetailsContainer>
                    {/* <SearchPriceContainer>
                    {product.price ? (
                      <>
                        <div>
                          {displayCurrencyValue(
                            product.price,
                            locationDetails?.currency_symbol,
                            locationDetails?.multiplier
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <span>Out of stock</span>
                      </>
                    )}
                  </SearchPriceContainer> */}
                  </ListItem>
                </a>
              </Link>
            );
          })
        ) : (
          <NoProductFoundText>
            <NoProductsFound />
            {/* {no_product_found_msg} */}
          </NoProductFoundText>
        )}
        {/* <Link href={`/store?phrase=${props.mobileInputRef?.current.value}`}> */}
        {props.products && props.products.length > 7 && (
          <div style={{ padding: '8px 16px' }}>
            <Button
              buttonTheme="grey"
              width="100%"
              height="48px"
              onClick={(e) => {
                props.goToStore();
              }}
            >
              See more
            </Button>
          </div>
        )}
        {/* </Link> */}
      </AutocompleteList>
    </AutocompleteContainer>
  );
};

export default memo(SearchAutocomplete);
