import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const AutocompleteContainer = styled.div`
  display: block;
  background: ${(props) => props.theme.palette.background.bg1};
  border-radius: 8px;
  box-sizing: border-box;
  // box-shadow: 0px 10px 70px rgb(0 0 0 / 15%);
  // border: none;
  border: 1px solid ${(props) => props.theme.palette.border.b2};
  position: absolute;
  width: 100%;
  margin-top: 16px;
  z-index: 10;
  left: 0;
  overflow: hidden;
  transition: 0.1s all ease;
  @media (max-width: ${WIDTH.tabletMax}) {
    width: calc(100% - 32px);
    margin: -4px 16px 0;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    width: calc(100% - 32px);
    margin: -4px 16px 0;
    left: 0;
  }
`;

export const AutocompleteHead = styled.div`
  font-size: 18px;
  font-family: Onest-Bold;
  letter-spacing: 0px;
  line-height: 21px;
  color: ${(props) => props.theme.palette.text.t1};
  padding: 0 20px 20px 20px;
`;

export const AutocompleteList = styled.ul`
  list-style: none;
  margin: 20px 5px 0 0;
  padding: 0;
  max-height: 500px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 30px;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.background.bg1};
    border-radius: 30px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.background.bg3};
    border-radius: 30px;
    padding: 20px 0;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.palette.background.bg3};
    border-radius: 30px;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    height: 100%;
    // max-height: none;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    height: 100%;
    max-height: 473px;
  }
`;

export const SearchLoaderContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ListItemInterface {
  selected: boolean;
}

export const ListItem = styled.li<ListItemInterface>`
  padding: 12px 20px;
  min-height: 115px;
  display: flex;
  border-top: 1px solid ${(props) => props.theme.palette.divider.d1};

  &:hover {
    background: ${(props) => props.theme.palette.background.bg3};
  }
  // @media (max-width: 1440px) {
  //   padding: 8px;
  // }
  overflow: hidden;
`;

export const ListTitle = styled.div`
  font: 1rem/1.25rem Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
  margin-bottom: 12px;
`;

export const ListDetailsContainer = styled.div`
  font: normal normal normal 14px/22px Onest-Regular;
`;

export const ImageContainer = styled.div`
  width: 72px;
  height: 102px;
  overflow: hidden;
  border-radius: 6px;
`;

export const ImageTag = styled.img`
  /* width: 100%; */
  object-fit: cover;
  aspect-ratio: 72/102;
  width: 100%;
  border-radius: 6px;
`;

export const ProductRegion = styled.div`
  display: flex;
  gap: 6px;
  font: 0.875rem/1rem Onest-Bold;
  margin-bottom: 2px;
  font-family: Onest-Bold;
  text-transform: uppercase;
  align-items: center;
  color: ${(props) => props.theme.palette.text.t1};
`;

export const DetailsContainer = styled.div`
  flex: 1;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* > div {
    &:nth-child(1) {
      font-family: Onest-SemiBold;
      font-size: 16px;
      margin-bottom: 0.6rem;
    }
    &:nth-child(2) {
      font-family: Onest-Regular;
      font-size: 14px;
    }
  } */
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  font-size: 14px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  &:hover {
    font-family: Onest-SemiBold;
  }
  &:focus {
    transform: scale(0.9);
  }
`;

export const SearchPriceContainer = styled.div`
  width: 100px;
  /* height: 100%; */
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* color: #6303e0;
   */
  font-size: 16px;
  font-family: Onest-Bold;
  @media (max-width: 1440px) {
    font-size: 14px;
  }
`;

export const PriceValue = styled.span`
  font: 1.25rem/1.5rem Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
`;

export const MrpValue = styled.s`
  margin: 2px 14px 0px 8px;
  font: 1.25rem/1.5rem Onest-Medium
  color: ${(props) => props.theme.palette.background.bg2};
`;

export const SimpleText = styled.span`
  font: 1rem/1.25rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t2};
`;

export const NoProductFoundText = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: ${(props) => props.theme.palette.text.t2};
  font: 1rem/1rem Onest-Bold;
  text-align: center;
  margin: -20px auto 32px;
`;

export const PreReleasedTag = styled.div`
  color: ${(props) => props.theme.palette.warning.main};
  border: 1px solid ${(props) => props.theme.palette.warning.main};
  border-radius: 4px;
  padding: 5px 4px;
  font-size: 12px !important;
  line-height: 14px !important;
  text-transform: none !important;
  padding-top: 3px;
  margin-left: 8px;
`;
