import React from 'react';
import { useTheme } from 'styled-components';
import { MaskIcon } from '../../Icons/Icons';

import { NoProductsFoundContainer } from '../WebListerStyle';

const NoProductsFound: React.FC = () => {
  const theme = useTheme();
  return (
    <NoProductsFoundContainer>
      <MaskIcon
        url={'/icons/manage_search.svg'}
        width="120px"
        height="120px"
        selected
        margin="0"
        color={theme.palette.text.t3}
      />
      {/* <Image 
        src={'/icons/no-products-found.webp'}
        width="300px"
        height="225px"
      /> */}
      <p>No Products Found</p>
      <p>Try refining your request</p>
    </NoProductsFoundContainer>
  );
};

export default NoProductsFound;
