import dynamic from 'next/dynamic';
import { getIconFromPlatformName } from '../../helper/helper';
// import { BackgroundIcon, MaskIcon } from './Icons';

// const BackgroundIcon = dynamic(() => import('./Icons'), {
//   ssr: false,
// });


const MaskIcon = dynamic(() => import('./Icons').then((mod) => mod.MaskIcon), {
  ssr: false,
});

const BackgroundIcon = dynamic(
  () => import('./Icons').then((mod) => mod.BackgroundIcon),
  {
    ssr: false,
  }
);




export const getIconUrl = (str: string, bw?: boolean) => {
  let url = `/icons/platform_icons/other.svg`;
  switch (str) {
    case 'Android':
      url = '/icons/platform_icons/google-play-icon.svg';
      break;
    case 'Bethesda':
      url = '/icons/platform_icons/bethesda.svg';
      break;
    case 'Blizzard':
      url = '/icons/platform_icons/blizzard.svg';
      break;
    case 'Battle.net':
      url = '/icons/platform_icons/blizzard.svg';
      break;
    case 'Epic Games':
      url = '/icons/platform_icons/epic_games.svg';
      break;
    case 'GOG COM':
      url = bw
        ? '/icons/platform_icons/gogcom-bw.svg'
        : '/icons/platform_icons/gog.svg';
      break;
    case 'Mojang':
      url = bw
        ? '/icons/platform_icons/mojang-bw.svg'
        : '/icons/platform_icons/mojang.svg';
      break;
    case 'NCSoft':
      url = '/icons/platform_icons/ncsoft.svg';
      break;
    case 'Nintendo':
      url = bw
        ? '/icons/platform_icons/nintendo-bw.svg'
        : '/icons/platform_icons/nintendo.svg';
      break;
    case 'Origin':
      url = bw
        ? '/icons/platform_icons/origin-bw.svg'
        : '/icons/platform_icons/origin.svg';
      break;
    case 'EA Origin':
      url = bw
        ? '/icons/platform_icons/origin-bw.svg'
        : '/icons/platform_icons/origin.svg';
      break;
    case 'EA Play':
      url = bw
        ? '/icons/platform_icons/ea-play.svg'
        : '/icons/platform_icons/ea-play.svg';
      break;
    case 'PlayStation 3':
      url = bw
        ? '/icons/platform_icons/playstation-bw.svg'
        : '/icons/platform_icons/psn.svg';
      break;
    case 'PlayStation 4':
      url = bw
        ? '/icons/platform_icons/playstation-bw.svg'
        : '/icons/platform_icons/psn.svg';
      break;
    case 'PlayStation 5':
      url = bw
        ? '/icons/platform_icons/playstation-bw.svg'
        : '/icons/platform_icons/psn.svg';
      break;
    case 'PlayStation Vita':
      url = bw
        ? '/icons/platform_icons/playstation-bw.svg'
        : '/icons/platform_icons/psn.svg';
      break;
    case 'PSN':
      url = bw
        ? '/icons/platform_icons/playstation-bw.svg'
        : '/icons/platform_icons/psn.svg';
      break;
    case 'Rockstar Games':
      url = bw
        ? '/icons/platform_icons/rockstar-bw.svg'
        : '/icons/platform_icons/rockstar.svg';
      break;
    case 'Steam':
      url = bw
        ? '/icons/platform_icons/steam-bw.svg'
        : '/icons/platform_icons/steam.svg';
      break;
    case 'Uplay':
      url = bw
        ? '/icons/platform_icons/ubisoft-bw.svg'
        : '/icons/platform_icons/uplay.svg';
      break;
    case 'XBOX 36O':
      url = bw
        ? '/icons/platform_icons/xbox-bw.svg'
        : '/icons/platform_icons/xbox.svg';
      break;
    case 'XBOX ONE':
      url = bw
        ? '/icons/platform_icons/xbox-bw.svg'
        : '/icons/platform_icons/xbox.svg';
      break;
    case 'XBOX Series X|S':
      url = bw
        ? '/icons/platform_icons/xbox-bw.svg'
        : '/icons/platform_icons/xbox.svg';
      break;
    case 'Xbox Live':
      url = bw
        ? '/icons/platform_icons/xbox-bw.svg'
        : '/icons/platform_icons/xbox.svg';
      break;
    case 'Ubisoft Connect':
      url = bw
        ? '/icons/platform_icons/ubisoft-bw.svg'
        : '/icons/platform_icons/uplay.svg';
      break;
    case 'Microsoft Store':
      url = bw
        ? '/icons/platform_icons/microsoft-store-bw.svg'
        : '/icons/platform_icons/microsoft-store.svg';
      break;
    case 'Roblox':
      url = bw
        ? '/icons/platform_icons/roblox.svg'
        : '/icons/platform_icons/roblox.svg';
      break;
    case 'FM Redeem':
      url = bw
        ? '/icons/platform_icons/fm-redeem-bw.svg'
        : '/icons/platform_icons/fm-redeem.svg';
      break;
    case 'Amazon':
      url = bw
        ? '/icons/platform_icons/Amazon.svg'
        : '/icons/platform_icons/Amazon.svg';
      break;
    case 'Apple':
      url = bw
        ? '/icons/platform_icons/apple-icon-24.svg'
        : '/icons/platform_icons/apple-icon-24.svg';
      break;

    case 'AstroPay':
      url = bw
        ? '/icons/platform_icons/Astropay.svg'
        : '/icons/platform_icons/Astropay.svg';
      break;
    case 'Bigo Live':
      url = bw
        ? '/icons/platform_icons/Bigo-Live.svg'
        : '/icons/platform_icons/Bigo-Live.svg';
      break;
    case 'Binance':
      url = bw
        ? '/icons/platform_icons/Binance.svg'
        : '/icons/platform_icons/Binance.svg';
      break;
    case 'Cashlib':
      url = bw
        ? '/icons/platform_icons/Cashlib.svg'
        : '/icons/platform_icons/Cashlib.svg';
      break;
    case 'Columbia Sportwear':
      url = bw
        ? '/icons/platform_icons/Columbia.svg'
        : '/icons/platform_icons/Columbia.svg';
      break;
    case 'Gocash':
      url = bw
        ? '/icons/platform_icons/GoCash-Game.svg'
        : '/icons/platform_icons/GoCash-Game.svg';
      break;
    case 'Jeton':
      url = bw
        ? '/icons/platform_icons/Jeton.svg'
        : '/icons/platform_icons/Jeton.svg';
      break;
    case 'Neosurf':
      url = bw
        ? '/icons/platform_icons/Neosurf.svg'
        : '/icons/platform_icons/Neosurf.svg';
      break;
    case 'Netflix':
      url = bw
        ? '/icons/platform_icons/Netflix.svg'
        : '/icons/platform_icons/Netflix.svg';
      break;

    case 'Old Navy':
      url = bw
        ? '/icons/platform_icons/Old-Navy.svg'
        : '/icons/platform_icons/Old-Navy.svg';
      break;

    case 'Google Play':
      url = bw
        ? '/icons/platform_icons/Play-Store.svg'
        : '/icons/platform_icons/Play-Store.svg';
      break;

    case 'Riot Access':
      url = bw
        ? '/icons/platform_icons/Riot-Access-Code.svg'
        : '/icons/platform_icons/Riot-Access-Code.svg';
      break;

    case 'Spotify':
      url = bw
        ? '/icons/platform_icons/Spotify.svg'
        : '/icons/platform_icons/Spotify.svg';
      break;

    case 'Starbucks':
      url = bw
        ? '/icons/platform_icons/Starbucks.svg'
        : '/icons/platform_icons/Starbucks.svg';
      break;

    case 'Valorant':
      url = bw
        ? '/icons/platform_icons/Valorant.svg'
        : '/icons/platform_icons/Valorant.svg';

      break;

    case 'Walmart':
      url = bw
        ? '/icons/platform_icons/Walmart.svg'
        : '/icons/platform_icons/Walmart.svg';
      break;

    default:
      url = '/icons/platform_icons/other.svg';
      break;
  }

  return url;
};

export const exportPlatformIcon = (
  x: string,
  height: string,
  width: string,
  margin: string,
  color?: string
) => {
  // const url = getIconUrl(x, !!color);
  const url = getIconFromPlatformName(x);
  
  if (color) {
    return (
      <MaskIcon
        url={url}
        selected={true}
        color={color}
        height={height}
        width={width}
        margin={margin}
      />
    );
  }
  return (
    <>
    <BackgroundIcon url={url} height={height} width={width} margin={margin} />
    </>
  );
};
